import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "icon" }
const _hoisted_4 = { class: "message" }
const _hoisted_5 = { class: "subtext" }
const _hoisted_6 = { class: "link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon)))
      ]),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.message), 1),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.subtext), 1),
      _createVNode(_component_router_link, { to: _ctx.link }, {
        default: _withCtx(() => [
          _createElementVNode("a", _hoisted_6, _toDisplayString(_ctx.linkText), 1)
        ]),
        _: 1
      }, 8, ["to"])
    ])
  ]))
}